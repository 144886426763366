var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app",
      class: { "c-dark-theme": _vm.$store.getters.darkMode },
    },
    [
      _c(
        "CContainer",
        {
          staticClass:
            "d-flex align-items-center justify-content-center min-vh-100",
        },
        [
          _c(
            "CRow",
            { staticClass: "w-100 justify-content-center" },
            [
              _c("CCol", { attrs: { md: "10" } }, [
                _c("div", { staticClass: "w-100" }, [
                  _c("div", { staticClass: "clearfix" }, [
                    _c("h4", {
                      staticClass: "text-center",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("pageTechnicalWorks.title")),
                      },
                    }),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }