<template>
  <div :class="{ 'c-dark-theme': $store.getters.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center justify-content-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="10">
          <div class="w-100">
            <div class="clearfix">
              <h4 class="text-center" v-html="$t('pageTechnicalWorks.title')" />
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'PageTechnicalWorks'
}
</script>
